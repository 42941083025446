<template>
  <section class="content">
    <table class="table table-hover" ref="tbl_pengajuan_mt">
      <thead>
        <tr>
          <th>Nomor PO</th>
          <th>Tanggal</th>
          <th>Diajukan Oleh</th>
          <th>Cabang</th>
          <th>Nilai Total</th>
          <th>Keperluan</th>
          <th>Disetujui Oleh</th>
          <th>Status</th>
          <th>Dokumen</th>
          <th>LPJ</th>
          <th>Approval LPJ</th>
        </tr>
      </thead>
      <tbody @click="handleClick"></tbody>
    </table>
    <div
      class="modal"
      tabindex="-1"
      role="dialog"
      ref="formDialog"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document" style="max-width:600px">
        <div class="modal-content">
          <form role="form" @submit="submitForm" id="formMT">
            <div class="modal-header">
              <h5 class="modal-title">{{ formTitle }}</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <!-- <p v-if="errors.length" class="alert alert-danger">
                <b>Please correct the following error(s):</b>
              </p> -->
              <div class="row">
                <div class="col-md-6 form-group">
                  <label class="control-label">Staff</label>
                  <v-select :options="optStaff" v-model="form.staff_id" :reduce="opt => opt.id" />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Tanggal</label>
                  <datepicker v-model="form.date" id="tanggal" />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Keterangan</label>
                  <textarea class="form-control" v-model="form.keterangan"></textarea>
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Nilai Total</label>
                  <input
                    id="nilai_total"
                    class="form-control"
                    v-model="form.nilai_total"
                    type="number"
                    name="Nilai Total"
                  />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Cabang</label> <br />
                  <v-select :options="optBranch" v-model="form.branch_id" :reduce="opt => opt.id" />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary">
                Save changes
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="modal" tabindex="-1" role="dialog" ref="formUpload" data-backdrop="static">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <form role="form">
                    <div class="modal-header">
                        <h5 class="modal-title">Upload LPJ</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body row">
                        <p v-if="errors.length" class="alert alert-danger">
                            <b>Please correct the following error(s):</b>
                            <ul>
                                <li v-for="error in errors">{{ error }}</li>
                            </ul>
                        </p>
                        <div class="col-md-12 form-group">
                            <div class="custom-file">
                                <input type="file" class="custom-file-input inputfile" id="inputFile" name="file" @change="uploadFoto(this)">
                                <label class="custom-file-label" for="inputFile" ref="imgname">Choose file</label>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button"  @click="submitUpload" class="btn btn-primary submit"><span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{{ loading?" Sedang menyimpan...":"Save changes" }}</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="modal" tabindex="-1" role="dialog" ref="formUploadDoc" data-backdrop="static">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <form role="form">
                    <div class="modal-header">
                        <h5 class="modal-title">Upload Dokumen</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body row">
                        <p v-if="errors.length" class="alert alert-danger">
                            <b>Please correct the following error(s):</b>
                            <ul>
                                <li v-for="error in errors">{{ error }}</li>
                            </ul>
                        </p>
                        <div class="col-md-12 form-group">
                            <div class="custom-file">
                                <input type="file" class="custom-file-input inputfiledoc" id="inputFileDoc" name="file" @change="uploadFotoDoc(this)">
                                <label class="custom-file-label" for="inputFileDoc" ref="imgnamedoc">Choose filefile</label>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button"  @click="submitUploadDoc" class="btn btn-primary submit"><span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{{ loading?" Sedang menyimpan...":"Save changes" }}</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import { createTable, authFetch, formatCurrency } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";
import datepicker from "@/components/Datepicker";
import datePicker from "vue-bootstrap-datetimepicker";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";

export default {
  name: "",
  data() {
    return {
      errors: [],
      method: "",
      roles: "",
      formTitle: "Tambah Maintenance",
      tbl: '',
      optStaff: [],
      optBranch: [],
      optBarang: [],
      counterHarga: 0,
      counterQty: 0,
      counterTotal: 0,
      form: {
        staff_id: "",
        date: "",
        branch_id: "",
        keterangan: "",
        nilai_total: "",
        items: [],
        type: 5
      },
      loading: false,
      formUpload: {
          id: '',
      },
    };
  },
  created: function () {
    this.roles = this.$route.meta.roles;

    authFetch('/administrasi/pengajuan_budget/staff')
      .then(res => {
          if (res.status === 201) {

          } else if (res.status === 400) {}
          return res.json();
      })
      .then(js => {
          this.optStaff = js.data;
      })

    authFetch('/administrasi/pengajuan_budget/branch')
      .then(res => {
          if (res.status === 201) {

          } else if (res.status === 400) {}
          return res.json();
      })
      .then(js => {
          this.optBranch = js.data;
      })
  },
  components: {
    vSelect,
    datepicker,
    datePicker,
  },
  methods: {
    handleClick(e) {
      if (e.target.matches(".link-role")) {
        this.$router.push({ path: "/permit/" + e.target.dataset.id });
        return false;
      }
      if (e.srcElement.getAttribute('class') == 'btn btn-primary') {
          this.formUploadLPJ(e.srcElement.getAttribute('data-id'))
      }
      if (e.srcElement.getAttribute('class') == 'doc btn btn-primary') {
          this.formUploadDoc(e.srcElement.getAttribute('data-id'))
      }
      if (e.srcElement.getAttribute('class') == 'download btn btn-primary') {
        this.download(e.srcElement.getAttribute('data-href'), e.srcElement.getAttribute('data-name'));
      }
      if (e.srcElement.getAttribute('class') == 'btn btn-info preview') {
        this.preview(e.srcElement.getAttribute('data-href'));
      }
    },
    preview: function(data) {
        window.open(window.$apiUrl+data, "_blank");
    },
    loadBarang: async function() {
        let vm = this;
        const res = await authFetch('/administrasi/pengajuan_budget/barang');
        vm.optBarang = await res.json();
    },

    addItem: function() {
        this.counterHarga = 0;
        this.counterQty = 0;
        this.counterTotal = 0;
        var nRow = this.tbl.fnAddRow('<div class="btn-group"><a class="btn edit btn-xs icon-edit btn-primary"><i class="fas fa-pen"></i> Edit</a><a class="btn delete btn-xs icon-remove btn-danger"><i class="fa fa-trash"></i> Delete</a></div>');
        $('td .btn.edit', nRow).click();
    },

    submitForm: function (ev) {
      const e = this.$refs;
      let vm = this;

      var table = $('#tablebarang').DataTable();
      
      this.form.type = 5

      var data = Object.keys(this.form)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
        )
        .join("&");

      var urlSubmit = "/administrasi/pengajuan_budget";
      if (this.method == "PUT")
        urlSubmit = "/administrasi/pengajuan_budget/" + this.form.id;

      authFetch(urlSubmit, {
        method: this.method,
        body: data,
      })
        .then((res) => {
          if (res.status === 201) {
          } else if (res.status === 400) {
          }
          return res.json();
        })
        .then((js) => {
          this.errors = [];
          if (!js.success) {
            for (var key in js.details) {
              if (js.details.hasOwnProperty(key)) {
                this.errors.push(js.details[key]);
              }
            }

            return;
          }
          this.table.api().ajax.reload();
          $(e.formDialog).modal("hide");
        });

      ev.preventDefault();
    },
    uploadFoto : function (file) {
        var file_data = $('#inputFile').prop('files');
        console.log(file_data)
        var form_data = new FormData();
        var fileInput = $('#inputFile')[0];
        $.each(fileInput.files, function(k,file){
          form_data.append('file', file);
        });
        form_data.append('subdir', '/LPJ-pengajuan-maintenance/');
        form_data.append('id', this.formUpload.id);
        this.formUpload = form_data
        return false
    },
    uploadFotoDoc : function (file) {
        const e = this.$refs;
        var file_data = $('#inputFileDoc').prop('files');
        $(e.imgnamedoc).html(file_data[0].name);
        var form_data = new FormData();
        var fileInput = $('#inputFileDoc')[0];
        $.each(fileInput.files, function(k,file){
          form_data.append('file', file);
        });
        form_data.append('subdir', '/DOC-pengajuan-budget/');
        form_data.append('id', this.formUpload.id);
        this.formUpload = form_data
        return false
    },
    doUpload: function() {
        return authFetch('/administrasi/pengajuan_budget/upload_data', {
            method: 'POST',
            body: this.form_data,
            headers: {
            'Content-Type': null,
            }
        })
    },
    formUploadLPJ: function(id) {
        const e = this.$refs;
        this.formUpload.id = id
        $(e.imgname).html('Choose file');
        $(e.formUpload).modal('show');
    },
    formUploadDoc: function(id) {
        const e = this.$refs;
        this.formUpload.id = id
        $(e.imgnamedoc).html('Choose file');
        $(e.formUploadDoc).modal('show');
    },
    submitUpload: function() {
        var self = this;
        this.loading = true;
        $('.submit').attr('disabled','disabled')
        var file_data = $('#inputFile').prop('files');
        if (file_data.length == 0) {
            Swal.fire({
                icon: 'info',
                title: 'Informasi',
                text: 'Mohon upload LPJ!'
            })
            $('.submit').removeAttr('disabled')
            return false;
        }
        const e = this.$refs;
        authFetch('/administrasi/pengajuan_budget/upload_data', {
            method: 'POST',
            body: this.formUpload,
            headers: {
              'Content-Type': null,
            }
        }).then((res) => {
            this.loading = false;
            if (res.status === 201) {

            } else if (res.status === 400) {}

            return res.json();
            
        }).then(js => {
            this.errors = [];
                if (!js.status) {
                    for (var key in js) {
                        if (js.hasOwnProperty(key)) {
                            this.errors.push(js[key])
                        }
                    }

                    return;
                } else {
                    $(e.formUpload).modal('hide');
                    this.table.api().ajax.reload();
                    Swal.fire({
                        icon: 'success',
                        title: 'Informasi',
                        text: 'Data telah tersimpan.'
                    })
                    $('.submit').removeAttr('disabled')
                    this.formUpload.id =  ''
                }
        })
    },
    submitUploadDoc: function() {
        var self = this;
        this.loading = true;
        $('.submit').attr('disabled','disabled')
        var file_data = $('#inputFileDoc').prop('files');
        if (file_data.length == 0) {
            Swal.fire({
                icon: 'info',
                title: 'Informasi',
                text: 'Mohon upload Dokumen!'
            })
            $('.submit').removeAttr('disabled')
            return false;
        }
        const e = this.$refs;
        authFetch('/administrasi/pengajuan_budget/upload_data_doc', {
            method: 'POST',
            body: this.formUpload,
            headers: {
              'Content-Type': null,
            }
        }).then((res) => {
            this.loading = false;
            if (res.status === 201) {

            } else if (res.status === 400) {}

            return res.json();
            
        }).then(js => {
            this.loading = false;
            $('.submit').removeAttr('disabled')
            this.errors = [];
                if (!js.status) {
                    for (var key in js) {
                        if (js.hasOwnProperty(key)) {
                            this.errors.push(js[key])
                        }
                    }

                    return;
                } else {
                    $(e.formUploadDoc).modal('hide');
                    this.table.api().ajax.reload();
                    Swal.fire({
                        icon: 'success',
                        title: 'Informasi',
                        text: 'Data telah tersimpan.'
                    })
                    this.formUpload.id =  ''
                }
        })
    },
    download: function(code, name) {
        this.loadingDownload = true;
        Swal.fire({
              title: "Mohon tunggu...",
              text: "Sedang mengambil data",
              imageUrl: "images/ajaxloader.gif",
              showConfirmButton: false,
              allowOutsideClick: false,
              buttons: false,
            });
        authFetch("/accounting/pengajuan/download/"+code, {
            method: 'GET',
            headers: {
            'Content-Type': null,
            }
        })
        .then(response => {
          console.log(response)
          // const filename =  response.headers.get('Content-Disposition');//.split('filename=')[1];
          response.blob().then(blob => {
              this.loadingDownload = false;
              Swal.fire({
                  title: "Sukses",
                  text: "Load data berhasil",
                  showConfirmButton: false,
                  showOkButton: false,
                  buttons: false,
                  timer: 1000
                });
              var url = window.URL.createObjectURL(blob);
              var a = document.createElement('a');
              a.href = url;
              a.download = name;//filename;//"template.xlsx";
              // console.log(blob)
              // console.log(url)
              document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
              a.click();    
              a.remove();  //afterwards we remove the element again         
          });
        })
    },
  },
  async mounted() {
    const e = this.$refs;
    let self = this;
    this.table = createTable(e.tbl_pengajuan_mt, {
      title: "",
      roles: this.$route.params.roles,
      ajax: "/administrasi/pengajuan_budget/5",
      scrollX: true,
      columns: [
        { data: "code" },
        { data: "date" },
        { data: "staff" },
        { data: "branch" },
        { data: "nilai_total" },
        { data: "keterangan" },
        { data: "approval_name" },
        { data: "status" },
        { data: "filecode_doc" },
        { data: "filecode_lpj" },
        { data: "approval_lpj" },
      ],
      filterBy: [1, 2, 3, 4, 5],
      frame: true,
      rowCallback: function (row, data) {
        let idr = Intl.NumberFormat("en-ID", {
            style: "currency",
            currency: "IDR",
            useGrouping: true,
            maximumSignificantDigits: 3,
        }); 
        // $("td:eq(4)", row).html(idr.format(data.nilai_total));
        $("td:eq(4)", row).html("Rp "+formatCurrency(data.nilai_total));

        if (data.status == '1') {
          $("td:eq(7)", row).html('<span style="padding:0.5em;font-size:94%" class="badge badge-success">Approved</span>');
          if (data.approval_lpj == '1') {
            $("td:eq(10)", row).html('<span style="padding:0.5em;font-size:94%" class="badge badge-success">Approved</span>');
            $('td:eq(9)', row).html('<div class="row"><a type="button" class="btn btn-info preview" data-href="/file/view/'+data.filecode_lpj+'" data-name="'+data.file_actual+'"><i class="far fa-eye" style="pointer-events:none"></i></a><a type="button" class="download btn btn-primary" data-href="'+data.filecode_lpj+'" data-name="'+data.file_actual+'"><i class="fas fa-download" style="pointer-events:none"></i></a></div>');
          } else if (data.approval_lpj == '0') {
            if (data.filecode_lpj) {
              $('td:eq(9)', row).html('<div class="row"><a type="button" class="btn btn-info preview" data-href="/file/view/'+data.filecode_lpj+'" data-name="'+data.file_actual+'"><i class="far fa-eye" style="pointer-events:none"></i></a><a type="button" class="download btn btn-primary" data-href="'+data.filecode_lpj+'" data-name="'+data.file_actual+'"><i class="fas fa-download" style="pointer-events:none"></i></a></div>');
              $("td:eq(10)", row).html('<span style="padding:0.5em;font-size:94%" class="badge badge-warning">Waiting</span>');
            } else {
              $("td:eq(10)", row).html('<span style="padding:0.5em;font-size:94%" class="badge badge-info">Belum Ada LPJ</span>');
              $("td:eq(9)", row).html('<a type="button" class="btn btn-primary" data-toggle="modal"style="color:white" data-id="'+data.id+'">Upload</a>');
            }
          } else {
            $("td:eq(10)", row).html('<span style="padding:0.5em;font-size:94%" class="badge badge-danger">Rejected</span>');
            $("td:eq(9)", row).html('<a type="button" class="btn btn-primary" data-toggle="modal"style="color:white" data-id="'+data.id+'">Upload</a>');
          }
        } else if (data.status == '0') {
          $("td:eq(7)", row).html('<span style="padding:0.5em;font-size:94%" class="badge badge-primary">Waiting</span>');
          $("td:eq(9)", row).html('Approval Belum disetujui');
          $("td:eq(10)", row).html('<span style="padding:0.5em;font-size:94%" class="badge badge-info">Belum Ada LPJ</span>');
        } else {
          $("td:eq(7)", row).html('<span style="padding:0.5em;font-size:94%" class="badge badge-danger">Rejected</span>');
          $("td:eq(9)", row).html('Approval Belum disetujui');
          $("td:eq(10)", row).html('<span style="padding:0.5em;font-size:94%" class="badge badge-info">Belum Ada LPJ</span>');
        }

        if (data.filecode_doc) {
          $('td:eq(8)', row).html('<div class="row"><a type="button" class="btn btn-info preview" data-href="/file/view/'+data.filecode_doc+'" data-name="'+data.file_actual+'"><i class="far fa-eye" style="pointer-events:none"></i></a><a type="button" class="download btn btn-primary" data-href="'+data.filecode_doc+'" data-name="'+data.file_actual+'"><i class="fas fa-download" style="pointer-events:none"></i></a></div>');
        } else {
          $("td:eq(8)", row).html('<a type="button" class="doc btn btn-primary" data-toggle="modal"style="color:white" data-id="'+data.id+'">Upload</a>');
        }

        if (data.tipe_pengajuan == 1) {
          $("td:eq(4)", row).html("Perlengkapan");
        }
        if (data.tipe_pengajuan == 2) {
          $("td:eq(4)", row).html("Perlengkapan ATK");
        }
        if (data.tipe_pengajuan == 3) {
          $("td:eq(4)", row).html("Peralatan dan Biaya Operational");
        }
        if (data.tipe_pengajuan == 4) {
          $("td:eq(4)", row).html("Biaya lain-lain");
        }
      },
      buttonClick: (evt) => {
        if (evt.role == "create") {
          self.form = {};
          self.method = "POST";
          self.errors = [];
          self.formTitle = "Tambah Maintenance";
          $(e.formDialog).modal("show");
        } else if (evt.role == "update" && evt.data) {
          self.form = evt.data;console.log(evt.data)
          self.form.status = evt.data.status == 1 ? true : false; //operator ternary
          self.method = "PUT";
          self.errors = [];
          self.formTitle = "Edit Maintenance";
          // this.tbl.api().ajax.reload();
          $(e.formDialog).modal("show");
          this.counterHarga = 0;
          this.counterQty = 0;
          this.counterTotal = 0;
        } else if (evt.role == "delete" && evt.data) {
          // self.form = evt.data;
          Swal.fire({
            title: "Hapus data?",
            icon: "question",
            denyButtonText: '<i class="fa fa-times"></i> Hapus',
            showCancelButton: true,
            showDenyButton: true,
            showConfirmButton: false,
          }).then((result) => {
            if (result.isDenied) {
              authFetch("/administrasi/pengajuan_budget/" + evt.data.id, {
                method: "DELETE",
                body: "id=" + evt.data.id,
              })
                .then((res) => {
                  return res.json();
                })
                .then((js) => {
                  this.table.api().ajax.reload();
                });
            }
          });
        }
      },
    });
  },
};
</script>
<style>
  .card {
    width: inherit;
  }

  select .dt .editor {
    width: 100%;
  }

  .modal-body.row.card.box-table {
    width: 100%;
  }

  .icon-edit {
    color: white;
  }
  icon-remove {
    color: white; 
  }
</style>